/**
 * Layout component that queries for data
 * with Gatsby's StaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/static-query/
 */

import React, { useState } from "react"
import styled, { ThemeProvider } from "styled-components"

import DeviceProvider from "./elements/DeviceProvider"
import theme from "./theme"
import Navbar from "./Navbar"
import Footerv2 from "./Footerv2"
import "./layout.css"

const Wrapper = styled.div`
  overflow: hidden;
  position: relative;
  left: ${props => (props.isBurgerActive ? "-240px" : "0")};
  transition: all 0.3s cubic-bezier(0.78, 0.14, 0.15, 0.86);
  .dropdown-menu-enter-done {
    transform: translateY(0%);
    opacity: 1;
  }

  .dropdown-menu-exit-active {
    transform: translateY(-100%);
    opacity: 0;
  }
  .dropdown-enter-done {
    visibility: visible;
    opacity: 1;
    transform: translateY(0%);
    transition-delay: 0s, 0s, 0.3s;
  }

  .dropdown-exit-active {
    visibility: hidden;
    opacity: 0;
    transform: translateY(-2em);
  }
`

const LayoutEnglish = ({ children }) => {
  const [isBurgerActive, setToggleMenu] = useState(false)

  const handleToggleMenu = () => {
    setToggleMenu(!isBurgerActive)
  }

  return (
    <ThemeProvider theme={theme}>
      <DeviceProvider>
        <Wrapper isBurgerActive={isBurgerActive}>
          <Navbar
            handleToggleMenu={handleToggleMenu}
            isBurgerActive={isBurgerActive}
            isEnglish
          />
          {children}
          {/* <Footer englishPage /> */}
          <Footerv2 englishPage />
        </Wrapper>
      </DeviceProvider>
    </ThemeProvider>
  )
}

export default LayoutEnglish
