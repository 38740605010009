import React, { useState } from "react"
import styled, { keyframes } from "styled-components"
import { rubberBand } from "react-animations"

import Section from "../../elements/Section"
import Title from "../../elements/Title"
import IntroCard from "../../elements/IntroCard"
import Button from "../../elements/Button"
import globalVariables from "../../globalVariables"
import FlecheBleue from "../../../images/icons/fleche-bleue.svg"
import Img from "gatsby-image"

const rubberBandAnimation = keyframes`${rubberBand}`

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  @media (min-width: ${globalVariables.burgerBreakpoint}) {
    flex-direction: row;
    height: 540px;
  }
`

const WrapperItem = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1 1 100%;
  @media (min-width: ${globalVariables.burgerBreakpoint}) {
    flex-direction: row;
  }
`

const Icon = styled.img`
  width: 55px;
  @media (min-width: ${globalVariables.burgerBreakpoint}) {
    width: 74px;
    margin-bottom: 54px;
  }
`

const Item = styled.div`
  border-top: 1px solid white;
  position: relative;
  text-align: center;
  transition: all 0.2s ease-in-out;
  height: ${props => (props.isActive ? "350px" : "110px")};
  &:hover ${Icon} {
    animation: 1s ${rubberBandAnimation};
  }
  @media (min-width: ${globalVariables.burgerBreakpoint}) {
    flex: ${props => (props.isActive ? "1 0 35%" : "0 1 20%")};
    border-left: 1px solid white;
    border-top: 0;
    height: auto;
  }
`

const WrapperFleche = styled.div`
  display: none;
  @media (min-width: ${globalVariables.burgerBreakpoint}) {
    position: absolute;
    top: 50%;
    transform: ${props =>
      props.isActive
        ? "translateY(-50%) rotate(0deg)"
        : "translateY(-50%) rotate(180deg)"};
    left: -15px;

    width: 30px;
    height: 31px;
    object-fit: contain;
    border-radius: 10px;
    background-color: #ffffff;
    display: flex;
    flex-direction: column;
    align-item: center;
    justify-content: center;
    transition: all 0.3s ease-in-out;
    img {
      width: 10px;
      margin: auto;
    }
  }
`

const WrapperPlus = styled.div`
  width: 56px;
  height: 56px;
  object-fit: contain;
  border-radius: 10px;
  background-color: ${props => props.theme.primary};
  position: relative;
  span {
    display: block;
    height: 2px;
    width: 20px;
    background-color: white;
    border-radius: 2px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);

    transition: all 0.2s cubic-bezier(0.215, 0.61, 0.355, 1);
    &:nth-child(2) {
      width: ${props => (props.isActive ? "20px" : "2px")};
      height: ${props => (props.isActive ? "2px" : "20px")};
    }
  }
  @media (min-width: ${globalVariables.burgerBreakpoint}) {
    display: none;
  }
`

const WrapperContent = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(81, 85, 93, 0.9);
  padding: 28px 23px 40px;
  transition: all 0.2s ease-in-out;
  @media (min-width: ${globalVariables.burgerBreakpoint}) {
    max-height: none;
    padding: 50px 15px 60px;
    height: 100%;
    background-color: ${props =>
      props.isActive ? "rgba(81, 85, 93, 0.9)" : "rgba(170, 170, 170, 0.8)"};
  }
`

const Header = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  .title2 {
    margin-bottom: 0;
    margin-left: 20px;
  }
  @media (min-width: ${globalVariables.burgerBreakpoint}) {
    flex-direction: column;

    .title2 {
      margin-bottom: 1.45rem;
      margin-left: 0;
    }
  }
`

const WrapperHeader = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  @media (min-width: ${globalVariables.burgerBreakpoint}) {
    flex-direction: column;
  }
`

const Content = styled.div`
  opacity: ${props => (props.isActive ? "1" : "0")};
  height: ${props => (props.isActive ? "auto" : "0")};
  transform: ${props =>
    props.isActive ? "translateX(0)" : "translateX(-100px)"};
  transition: opacity 0.1s ease-in-out, transform 0.2s ease,
    height 3s ease-in-out;
  ${Item}:hover & {
    transition: height 0.2s ease-in-out, opacity 0.4s ease-in-out,
      transform 0.6s ease;
  }
  @media (min-width: ${globalVariables.burgerBreakpoint}) {
    height: auto;
    transition: opacity 0.1s ease-in-out, transform 0.2s ease;
    ${Item}:hover & {
      transition: opacity 0.4s ease-in-out, transform 0.6s ease;
    }
  }
`

const Text = styled.p`
  color: white;
  padding: 25px 0 25px;
  @media (min-width: ${globalVariables.burgerBreakpoint}) {
    padding: 25px 10px 25px;
  }
  @media (min-width: ${globalVariables.bigDesktop}) {
    padding: 25px 25px 25px;
  }
`

const Expertises = ({ title, text, items, englishPage }) => {
  const [itemHover, setItemHover] = useState(items[0].expertise.titre_expertise)

  const handleMouseOver = value => {
    if (window.innerWidth > 1210) {
      setItemHover(value)
    }
  }
  const handleClickPlus = value => {
    if (itemHover === value) {
      setItemHover("")
    } else {
      setItemHover(value)
    }
  }

  const getPathname = url => new URL(url).pathname

  return (
    <Section padding="0" id="expertises">
      <Wrapper>
        <IntroCard
          englishPage={englishPage}
          bgColor
          title={title}
          text={text}
          buttonText={
            typeof window !== "undefined" && window.innerWidth > 768
              ? "Demander un audit"
              : "Contactez-nous"
          }
          buttonUrl="/contact"
        />
        <WrapperItem>
          {items.map((item, index) => (
            <Item
              key={index}
              onMouseEnter={() =>
                handleMouseOver(item.expertise.titre_expertise)
              }
              onMouseLeave={event => event.preventDefault()}
              isActive={itemHover === item.expertise.titre_expertise}
            >
              <Img
                fluid={
                  item.expertise.image_expertise.localFile.childImageSharp.fluid
                }
              />
              <WrapperContent
                isActive={itemHover === item.expertise.titre_expertise}
              >
                <Header>
                  <WrapperHeader>
                    <Icon
                      alt={item.expertise.titre_expertise}
                      src={
                        item.expertise.logo_expertise.localFile.childImageSharp
                          .fluid.src
                      }
                    />
                    <Title as="h2" className="title2" color="white">
                      {item.expertise.titre_expertise}
                    </Title>
                  </WrapperHeader>
                  <WrapperPlus
                    isActive={itemHover === item.expertise.titre_expertise}
                    onClick={() =>
                      handleClickPlus(item.expertise.titre_expertise)
                    }
                  >
                    <span />
                    <span />
                  </WrapperPlus>
                </Header>
                <Content
                  isActive={itemHover === item.expertise.titre_expertise}
                >
                  <Text>{item.expertise.texte_expertise}</Text>
                  {!englishPage && (
                    <Button
                      title="En savoir plus"
                      colors="primaryWithBg"
                      to={getPathname(item.expertise.lien_expertise)}
                      as="link"
                      className="gtm-button-en-savoir-plus"
                    />
                  )}
                </Content>
              </WrapperContent>
              <WrapperFleche
                isActive={itemHover === item.expertise.titre_expertise}
              >
                <img src={FlecheBleue} alt="fleche expertises" />
              </WrapperFleche>
            </Item>
          ))}
        </WrapperItem>
      </Wrapper>
    </Section>
  )
}

export default Expertises
