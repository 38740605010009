import React from "react"
import { graphql } from "gatsby"

import LayoutEnglish from "../components/layoutEnglish"
import SEO from "../components/seo"
import Expertises from "../components/Home/Expertises"
import Valeurs from "../components/Home/Valeurs"
import Accompagnement from "../components/Home/Accompagnement"
import Clients from "../components/Home/Clients"
import Certifications from "../components/Home/Certifications"
import Temoignage from "../components/Home/Temoignage"
import Contact from "../components/Home/Contact"
import BannerV2 from "../components/elements/BannersV2/BannerV2"

const anchors = [
  {
    content: "Our expertise",
    anchor: "expertises",
  },
  {
    content: "Our Values",
    anchor: "values",
  },
  {
    content: "Our approach",
    anchor: "accompagnements",
  },
  {
    content: "Contact Us",
    anchor: "contact-form",
  },
]

const bannerLeftCardContent = {
  mainContent:
    "Let's give meaning to your digital strategy by deploying all the mechanics of analysis and optimization of the performance of your digital devices.",
  titleFirstLine: "Digital Strategy",
  titleSecondLine: "Specialist Agency",
  subTitle: "100% Data & User Centrics",
}

const EnglishPage = ({ data, location }) => {
  //get WordPress data
  const { accueil } = data
  return (
    <LayoutEnglish>
      <SEO
        title={accueil.acf.title}
        description={accueil.acf.meta_description}
        lang="en"
        url={location.pathname}
      />
      <BannerV2
        bannerLeftCardContent={bannerLeftCardContent}
        anchors={anchors}
      />
      <Expertises
        englishPage
        title={accueil.acf.titre_expertise}
        text={accueil.acf.texte_expertise}
        items={accueil.acf.expertises}
      />
      <Valeurs
        image1={accueil.acf.image_1_valeurs}
        title={accueil.acf.titre_valeurs}
        items={accueil.acf.valeurs}
      />
      <Accompagnement
        englishPage
        title={accueil.acf.titre_accompagnement}
        text={accueil.acf.texte_accompagnement}
        items={accueil.acf.accompagnements}
      />
      <Clients
        englishPage
        title={accueil.acf.titre_references}
        text={accueil.acf.texte_reference}
        items={accueil.acf.reference}
      />
      <Certifications
        title={accueil.acf.titre_certification}
        text={accueil.acf.texte_certification}
        items={accueil.acf.certifications}
      />
      <Temoignage
        title={accueil.acf.titre_temoignages}
        items={accueil.acf.temoignages}
      />
      <Contact
        id="contact-form"
        title={accueil.acf.titre_contact}
        text={accueil.acf.texte_contact}
        location={location.href}
      />
    </LayoutEnglish>
  )
}

export default EnglishPage

export const query = graphql`
  query {
    accueil: wordpressPage(id: { eq: "8f63ffc5-47d0-503b-8927-dd6cdaae2bf8" }) {
      acf {
        title
        meta_description
        titre_banniere_1_new
        titre_banniere_2
        titre_banniere_3_new
        titre_banniere_4
        titre_banniere_partie_dynamique_new {
          element
        }
        image_banniere_new {
          localFile {
            childImageSharp {
              fluid(quality: 100, maxWidth: 3000) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
        }
        titre_expertise
        texte_expertise
        expertises {
          expertise {
            image_expertise {
              localFile {
                childImageSharp {
                  fluid(srcSetBreakpoints: 400, maxWidth: 400) {
                    ...GatsbyImageSharpFluid_withWebp
                  }
                }
              }
            }
            lien_expertise
            texte_expertise
            titre_expertise
            logo_expertise {
              localFile {
                childImageSharp {
                  fluid(quality: 100) {
                    src
                  }
                }
              }
            }
          }
        }
        titre_valeurs
        image_1_valeurs {
          localFile {
            childImageSharp {
              fluid(quality: 100) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
        }
        valeurs {
          intro_valeur
          texte_valeur
          titre_valeur
        }
        titre_accompagnement
        texte_accompagnement
        accompagnements {
          texte_accompagnement
          titre_accompagnement
          icone_accompagnement {
            localFile {
              childImageSharp {
                fluid(quality: 100) {
                  src
                }
              }
            }
          }
        }

        titre_references
        texte_reference
        reference {
          couleur_reference
          lien_reference
          image_reference {
            localFile {
              childImageSharp {
                fluid(srcSetBreakpoints: 400, maxWidth: 400) {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
          }
          logo_reference {
            localFile {
              childImageSharp {
                fluid(srcSetBreakpoints: 200, maxWidth: 200) {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
          }
        }
        titre_certification
        texte_certification
        certifications {
          logo_certification {
            alt_text
            localFile {
              childImageSharp {
                fluid(quality: 100) {
                  src
                }
              }
            }
          }
        }
        titre_temoignages
        temoignages {
          nom_temoignage
          texte_temoignage
          fonction_temoignage
          logo_temoignage {
            localFile {
              childImageSharp {
                fluid(srcSetBreakpoints: 200, maxWidth: 200) {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
          }
        }
        titre_contact
        texte_contact
      }
    }
  }
`
